.Pill-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Pill {
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  padding: 0 6px;
  min-width: 24px;
  white-space: nowrap;
  text-align: center;
  flex: 0 0 auto;
}

.Pill.Pill--colorRed {
  background-color: #fb5779;
  color: #fff;
}

.Pill.Pill--colorOrange {
  background-color: #ff7511;
  color: #331a00;
}

.Pill.Pill--colorYellowOrange {
  background-color: #ffa800;
  color: #4d3400;
}

.Pill.Pill--colorYellow {
  background-color: #ffd100;
  color: #574500;
}

.Pill.Pill--colorYellowGreen {
  background-color: #ace60f;
  color: #3c5007;
}

.Pill.Pill--colorGreen {
  background-color: #19db7e;
  color: #084426;
}

.Pill.Pill--colorBlueGreen {
  background-color: #00d4c8;
  color: #00423f;
}

.Pill.Pill--colorAqua {
  background-color: #48dafd;
  color: #02485a;
}

.Pill.Pill--colorBlue {
  background-color: #0064fb;
  color: #fff;
}

.Pill.Pill--colorIndigo {
  background-color: #6457f9;
  color: #fff;
}

.Pill.Pill--colorPurple {
  background-color: #9f46e4;
  color: #fff;
}

.Pill.Pill--colorMagenta {
  background-color: #ff78ff;
  color: #570057;
}

.Pill.Pill--colorHotPink {
  background-color: #ff4ba6;
  color: #fff;
}

.Pill.Pill--colorPink {
  background-color: #ff93af;
  color: #6b001d;
}

.Pill.Pill--colorCoolGray {
  background-color: #5a7896;
  color: #fff;
}

.Pill--colorNone {
  background-color: #e8ecee;
  color: #151b26;
}
