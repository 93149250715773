.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-row-wrap {
  flex-flow: row wrap;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.place-start {
  place-content: start;
}
.place-end {
  place-content: end;
}
.place-center {
  place-content: center;
}
.place-baseline {
  place-content: baseline;
}
.place-between {
  place-content: space-between;
}
.place-around {
  place-content: space-around;
}
.place-evenly {
  place-content: space-evenly;
}

.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}

.border-box {
  box-sizing: border-box;
}

.gap-16-desktop {
  @media (min-width: 960px) {
    gap: 16px;
  }
}

.column-gap-16-desktop {
  @media (min-width: 960px) {
    column-gap: 16px;
  }
}

.gap-8-desktop {
  @media (min-width: 960px) {
    gap: 8px;
  }
}

@media print {
  .gap-16-desktop {
    gap: 16px;
  }

  .column-gap-16-desktop {
    column-gap: 16px;
  }

  .gap-8-desktop {
    gap: 8px;
  }
}
