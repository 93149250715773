@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';
@import '@angular/cdk/overlay-prebuilt.css';
html,
body {
  font-size: 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}
@media (max-width: 960px) {
  html,
  body {
    font-size: 16px;
  }
}

p {
  margin: 8px 0;
}

.toolbar-admin {
  background-color: white;
  padding: 8px;
  text-align: right;
}

.analyze-filters-container {
  padding: 16px 16px 0 16px;
}

.search-section {
  display: flex;
  gap: 16px;
  align-items: baseline;
}

body > get-root > get-auth-inform-wrapper > lib-auth-inform > div > div > div {
  padding: 16px !important;
}

body > get-root > get-auth-inform-wrapper > lib-auth-inform > div > div > div > div > div:nth-child(1) {
  margin-bottom: 16px !important;
}

.app-border-legacy {
  padding: 0 8px 8px 8px;
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
}
.app-border-legacy .app-border-header-icon,
.app-border-legacy .app-border-subheader-icon {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
}
.app-border-legacy .app-border-header-icon span,
.app-border-legacy .app-border-subheader-icon span {
  color: black;
  font-weight: 700;
}
.app-border-legacy .app-border-header-icon > i,
.app-border-legacy .app-border-subheader-icon > i {
  padding-right: 10px;
  vertical-align: bottom;
  padding-left: 5px;
  display: none;
}
.app-border-legacy .app-border-header-icon span {
  font-size: 18px;
  margin-top: 4px;
}
.app-border-legacy .app-border-subheader-icon span {
  font-size: 15px;
}
.app-border-legacy .app-suivi {
  font-size: 0.8em;
  color: #949494;
  line-height: 1.5em;
}

.get-button.red {
  color: #e20000;
}

.file-add-button .mdc-button-wrapper {
  display: flex;
  align-items: center;
}
.file-add-button .mdc-button-wrapper i {
  display: flex;
}

@media (max-width: 960px) {
  .mdc-button,
  .mdc-stroked-button,
  .mdc-flat-button {
    font-size: 16px !important;
  }
}

.mdc-stroked-button .mdc-button-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  word-break: break-word;
}

@media (max-width: 960px) {
  .mdc-button {
    padding: 0 8px !important;
    min-width: 40px !important;
  }
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

.app-chip {
  padding: 0 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  padding: 0 1rem;
  border-radius: 1rem;
  font-size: 1rem;
}

.app-content-panel {
  overflow: auto;
}

.app-left-content-panel {
  width: 490px;
}

.fill-remaining-space {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  overflow: auto;
}

.reset-filter-button {
  position: absolute;
  bottom: 0;
  font-size: 15px;
  color: red;
  right: 4px;
}

.filter-icon {
  font-size: 18px;
}

/*
 * Classes used in src/app/core/configs/iconsHelper.config.ts
 */
.app-icon-star {
  color: orangered;
}

.app-icon-green {
  color: rgb(22, 145, 133);
}

.app-icon-blue {
  color: rgb(2, 147, 191);
}

.app-icon-red {
  color: #ea5555;
}

.app-icon-gray {
  color: rgb(102, 102, 102);
}

.trash-icon {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.trash-icon:hover {
  color: #ea5555 !important;
}

.display-star-empty {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.display-star-empty:hover {
  color: #f3b80f !important;
}

.display-star-full {
  color: #f3b80f !important;
}

.app-icon-pipe {
  vertical-align: middle;
  font-size: 20px;
  line-height: 16px;
}

.app-section {
  padding: 10px 25px 10px 25px;
  margin: 0 10px 25px 0;
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  display: block;
}
.app-section .app-section-title {
  margin: 0 0 15px 0 !important;
  font-size: 22px;
  line-height: 40px;
  font-weight: 500;
  position: relative;
  color: #19526c;
}
.app-section .app-section-title .material-icons-outlined {
  margin-right: 15px;
  vertical-align: bottom;
}
.app-section .app-section-title::before {
  content: "";
  width: 58px;
  height: 2px;
  background: #19526c;
  position: absolute;
  bottom: -10px;
  left: -29px;
}
.app-section .app-sub-section .app-sub-section-title {
  line-height: 50px;
  margin: 0;
  font-size: 18px;
  margin-top: 8px;
}
.app-section .app-sub-section a {
  color: #124159;
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 1350px) {
  .app-section {
    max-width: calc(100% - 250px);
  }
  .toc-container {
    width: 200px;
    position: fixed;
    top: 150px;
    right: 0;
    bottom: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.app-content {
  overflow: auto;
  height: 100%;
}

.app-strong {
  font-weight: bold;
}

.tool-panel-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex: 1 1 0%;
}

.admin-borad {
  width: 265px;
  margin-right: 4px;
}

.app-name-tag {
  background-color: #3f51b5;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
}

.info {
  background-color: whitesmoke;
  border-radius: 8px;
  color: darkgray;
  padding: 8px;
  margin: 0 0 16px 0 !important;
}
.info i {
  font-size: 24px;
  line-height: 24px;
}

.info-800 {
  width: 800px;
}

.info-700 {
  width: 700px;
}

.warn {
  background-color: #fff0f0;
  border-radius: 8px;
  color: rgb(238, 117, 117);
  padding: 8px;
  margin: 0 0 16px 0 !important;
}

.icons.open-in-new {
  font-size: 20px;
}

.icons.search-icon {
  margin-right: 5px;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
}

.icons.more-icon {
  line-height: 24px;
  font-size: 24px;
}

.icons.navigation-icon {
  font-size: 24px;
  color: #2b526c;
  margin-right: 16px;
  display: flex;
}

.icons.people-add {
  font-size: 24px;
}

.icons.redirect-icon {
  font-size: 24px;
}

.icons.green {
  color: #178e17;
}
.icons.red {
  color: #e20000;
}
.icons.blue {
  color: #2061c4;
}
.icons.grey {
  color: #535353;
}
.icons.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.Pill-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Pill {
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  padding: 0 6px;
  min-width: 24px;
  white-space: nowrap;
  text-align: center;
  flex: 0 0 auto;
}

.Pill.Pill--colorRed {
  background-color: #fb5779;
  color: #fff;
}

.Pill.Pill--colorOrange {
  background-color: #ff7511;
  color: #331a00;
}

.Pill.Pill--colorYellowOrange {
  background-color: #ffa800;
  color: #4d3400;
}

.Pill.Pill--colorYellow {
  background-color: #ffd100;
  color: #574500;
}

.Pill.Pill--colorYellowGreen {
  background-color: #ace60f;
  color: #3c5007;
}

.Pill.Pill--colorGreen {
  background-color: #19db7e;
  color: #084426;
}

.Pill.Pill--colorBlueGreen {
  background-color: #00d4c8;
  color: #00423f;
}

.Pill.Pill--colorAqua {
  background-color: #48dafd;
  color: #02485a;
}

.Pill.Pill--colorBlue {
  background-color: #0064fb;
  color: #fff;
}

.Pill.Pill--colorIndigo {
  background-color: #6457f9;
  color: #fff;
}

.Pill.Pill--colorPurple {
  background-color: #9f46e4;
  color: #fff;
}

.Pill.Pill--colorMagenta {
  background-color: #ff78ff;
  color: #570057;
}

.Pill.Pill--colorHotPink {
  background-color: #ff4ba6;
  color: #fff;
}

.Pill.Pill--colorPink {
  background-color: #ff93af;
  color: #6b001d;
}

.Pill.Pill--colorCoolGray {
  background-color: #5a7896;
  color: #fff;
}

.Pill--colorNone {
  background-color: #e8ecee;
  color: #151b26;
}

.campaign-step-title {
  display: flex;
  justify-content: center;
  margin: 16px 16px 28px 16px;
  font-size: 24px;
  font-weight: 500;
  max-width: 900px;
  text-align: center;
}

.collect-step-container {
  height: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}

.campaign-step-content {
  display: block;
  max-width: 900px;
  margin: 0 16px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
}

.campaign-step-content-caracs {
  display: block;
  max-width: 900px;
  border-radius: 8px;
}

/*
 * Default theme (only HTML tags customization here)
 */
html,
body {
  font-display: swap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  background-color: whitesmoke;
}

@media print {
  * {
    overflow: visible !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  .page {
    page-break-after: always;
  }
  .hide-on-print {
    display: none !important;
  }
  .sidenav-page-content {
    margin-left: 0 !important;
  }
  .print-avoid-page-break-inside {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
a {
  color: #19526c;
  text-decoration: none;
  cursor: pointer;
}
a:visited {
  color: #19526c;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
textarea::placeholder {
  color: #a7adb9;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
  overflow-x: overlay;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border: 3px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track-piece {
  width: 4px;
}

.ag-residence-sheet {
  max-width: 100%;
}

::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}

.padding-around-container {
  padding: 0 16px;
}

.light-gray-color {
  color: #a7a9ac;
}

.composant-list-container {
  width: 100%;
  gap: 16px;
}
.composant-list-container .composant-groupe-title-container {
  background-color: #19526c;
  border-radius: 4px;
  margin-bottom: 16px;
  height: 60px;
  padding: 16px;
}
.composant-list-container .composant-groupe-title-container .composant-groupe-icon i {
  font-size: 60px;
  display: flex;
  margin: 0 16px;
  color: #ffb902;
}
.composant-list-container .composant-groupe-title-container .composant-groupe-title {
  color: white;
  font-size: 22px;
}
.composant-list-container .composant-element {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  background: #f3f3f8;
  position: relative;
}
.composant-list-container .composant-element:hover {
  background-color: #e5e5ef;
}
.composant-list-container .composant-element .composant-element-title {
  line-height: 16px;
  white-space: break-spaces;
  text-align: center;
  width: 100%;
}
.composant-list-container .composant-element .composant-element-text {
  display: flex;
  justify-content: center;
}
.composant-list-container .composant-element .composant-element-subtitle {
  line-height: 16px;
  color: darkgrey;
}
.composant-list-container .composant-element .composant-element-badge {
  position: absolute;
  top: 70px;
  right: 35px;
  border-radius: 8px;
  padding: 0px 3px;
  line-height: 22px;
  min-width: 22px;
  height: 22px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}

.caracteristic-form-field-mobile {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.accent-500 {
  color: #ffb902;
}

.mobile-input {
  width: 90%;
  border: none;
  outline: 0;
  padding-top: 6px;
  font-size: 18px;
  background-color: transparent;
}
.mobile-input::placeholder {
  color: #a7adb9;
}

.mobile-label {
  font-size: 16px;
  font-weight: 400;
  color: #a7adb9;
}

.icon-right {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 15px;
  color: #a7adb9;
}

.colorNeantSelectorOption {
  color: #a7adb9;
}

.colorBlackSelectorOption {
  color: black;
}

.colorRedSelectorOption {
  color: red;
}

.mat-calendar-header {
  padding: 0px !important;
}

.full-height {
  height: 100%;
}

.progress-green-1 {
  background-color: #93dfa0;
  color: #027934;
}

.progress-green-2 {
  background-color: #c2e7a7;
  color: #687c59;
}

.progress-yellow {
  background-color: #fae87c;
  color: #9a8202;
}

.progress-orange {
  background-color: #ffcea3;
  color: #d86100;
}

.progress-red {
  background-color: #f4b7b8;
  color: #b80000;
}

.progress-grey {
  background-color: #f5f5f5;
  color: #727272;
}

@media (max-width: 960px) {
  lib-auth-form .card .card-header .logo {
    max-height: 128px !important;
  }
}
.form-field-max-width {
  max-width: 100%;
}

.information {
  color: white;
  background-color: #124159;
}

.attention {
  color: white;
  background-color: #ffaa01;
}

.important {
  color: white;
  background-color: #e44444;
}

.close-dialog {
  cursor: pointer;
  border-radius: 18px;
  padding: 8px;
  margin-left: 8px;
}
.close-dialog:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.shareUrl {
  text-decoration: none;
  margin-right: 16px;
}

@media print {
  body {
    min-width: 1000px !important;
  }
}
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row-wrap {
  flex-flow: row wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.place-start {
  place-content: start;
}

.place-end {
  place-content: end;
}

.place-center {
  place-content: center;
}

.place-baseline {
  place-content: baseline;
}

.place-between {
  place-content: space-between;
}

.place-around {
  place-content: space-around;
}

.place-evenly {
  place-content: space-evenly;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.border-box {
  box-sizing: border-box;
}

@media (min-width: 960px) {
  .gap-16-desktop {
    gap: 16px;
  }
}

@media (min-width: 960px) {
  .column-gap-16-desktop {
    column-gap: 16px;
  }
}

@media (min-width: 960px) {
  .gap-8-desktop {
    gap: 8px;
  }
}

@media print {
  .gap-16-desktop {
    gap: 16px;
  }
  .column-gap-16-desktop {
    column-gap: 16px;
  }
  .gap-8-desktop {
    gap: 8px;
  }
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 88vh !important;
  overflow: hidden !important;
}

.icon-ic_fluent_dismiss_24_regular {
  font-size: 20px;
}

.ag-theme-alpine .ag-ltr .ag-cell-focus {
  border: 1px solid rgb(22, 145, 133) !important;
}

[class*=ag-theme-] {
  --ag-row-group-indent-size: 20px;
}

.ag-grid-border-16 {
  border-right: 16px solid white;
  border-left: 16px solid white;
  box-sizing: border-box;
}

.ag-theme-alpine .ag-root-wrapper {
  border-right: 0px solid;
  border-left: 0px solid;
  border-top: solid 1px #bdc3c7;
}
.ag-theme-alpine .ag-row-group span {
  height: 33px !important;
  line-height: 33px !important;
}
.ag-theme-alpine .ag-ltr .ag-cell-focus {
  border: 1px solid rgb(22, 145, 133) !important;
}
.ag-theme-alpine .ag-row:not(.ag-full-width-row, .ag-row-group) {
  border-bottom-style: unset;
}
.ag-theme-alpine .ag-row:not(.ag-full-width-row, .ag-row-group) .ag-cell:not(.ag-cell-focus) {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px #e8ecee !important;
  border-right: solid 1px #e8ecee !important;
  border-left: solid 1px transparent !important;
}
.ag-theme-alpine .ag-header-cell::after,
.ag-theme-alpine .ag-header-group-cell::after {
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #bdc3c7;
}
.ag-theme-alpine .ag-row-drag {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0%29 --%3E%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 34 34' style='enable-background:new 0 0 34 34;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M23,3H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,3,23,3z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,13H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,13,23,13z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,23H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,23,23,23z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,33H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,33,23,33z'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
}
.ag-theme-alpine .lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.ag-theme-alpine .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 2px;
  border: 2px solid #19526c;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #19526c transparent transparent transparent;
}
.ag-theme-alpine .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ag-theme-alpine .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ag-theme-alpine .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ag-theme-alpine .ag-cell {
  padding: 0px 3px !important;
  text-overflow: clip;
  line-height: 31px;
}
.ag-theme-alpine .ag-header {
  background-color: #f1f1f1 !important;
  border-bottom-color: #e8ecee;
  border-bottom: 1px solid #bdc3c7;
}
.ag-theme-alpine .ag-header .ag-header-row {
  color: #6f7782;
  font-weight: 400 !important;
}
.ag-theme-alpine .ag-header .ag-header-row .ag-header-cell {
  padding: 0px 3px !important;
}
.ag-theme-alpine .ag-header .ag-header-row:first-child .ag-header-cell {
  border-top: 0px !important;
}
.ag-theme-alpine .ag-header .ag-header-row .ag-header-cell:hover {
  background-color: #f6f8f9;
  color: #151b26;
  fill: #151b26;
}
.ag-theme-alpine .ag-header .ag-header-row .ag-header-cell:hover .ag-header-icon {
  background-color: #f6f8f9;
}
.ag-theme-alpine .ag-header .ag-header-row .ag-cell-label-container {
  position: relative;
}
.ag-theme-alpine .ag-header .ag-header-row .ag-header-cell-menu-button {
  position: absolute;
  bottom: 0;
  height: 12px;
}
.ag-theme-alpine .ag-popup-editor {
  z-index: 1000;
}
.ag-theme-alpine .ag-popup-editor input {
  height: 30px;
}
.ag-theme-alpine .ag-popup-editor textarea {
  min-height: 30px;
}
.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 24px !important;
}
.ag-theme-alpine .ag-cell-inline-editing {
  height: 35px;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-0 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-0 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-0 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-0 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-0:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-2 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-2 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-2 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-2 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-2:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-3 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-3 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-3 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-3 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-3:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-4 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-4 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-4 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-4 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-4:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-5 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-5 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-5 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-5 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-5:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-6 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-6 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-6 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-6 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-6:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-7 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-7 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-7 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-7 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-7:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-8 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-8 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-8 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-8 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-8:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-9 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-9 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-9 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-9 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-9:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-10 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-10 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-10 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-10 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-10:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-11 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-11 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-11 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-11 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-11:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-12 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-12 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-12 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-12 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-12:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-13 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-13 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-13 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-13 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-13:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-14 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-14 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-14 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-14 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-14:hover {
  background-color: white !important;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-15 {
  color: #151b26 !important;
  font-size: 14px;
  font-weight: 700;
  border-bottom: solid 2px #e8ecee !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-15 div[col-id*=ag-Grid-AutoColumn] {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-15 div[col-id*=ag-Grid-AutoColumn] .ag-group-value {
  overflow: initial;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-15 .ag-icon {
  color: #151b26 !important;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-15:hover {
  background-color: white !important;
}

.ag-root {
  border: none !important;
}

.multiple-lines-header .ag-header-cell-label .ag-header-cell-text,
.multiple-lines-header .ag-header-group-text {
  white-space: normal !important;
  line-height: 16px;
  max-height: 35px;
}

.ag-row-odd,
.ag-row-even {
  background-color: #ffffff !important;
}

.ag-row-hover {
  background-color: #f6f8f9 !important;
}

.ag-row-selected {
  background-color: #bacbd3 !important;
}

.align-right {
  text-align: end;
}

.greyed-row {
  background-color: #e8e8e8 !important;
}

.greyed-text {
  color: #9e9e9e !important;
}

.bold-row {
  font-weight: 700;
}

.icon-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-cell-text i {
  font-size: 17px;
  padding-right: 5px;
}

.icon-cell-disabled {
  cursor: auto !important;
}

.icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-cell i {
  font-size: 21px;
  padding-right: 5px;
  padding-top: 2px;
}

.choix-cell {
  display: flex;
}
.choix-cell i {
  display: flex;
  align-items: center;
}
.choix-cell div {
  display: flex;
  align-self: center;
}

.svg-icon-cell {
  height: 100%;
}
.svg-icon-cell img {
  width: 28px;
}

.svg-icon-cell-analyze-synthetic {
  height: 100%;
}
.svg-icon-cell-analyze-synthetic img {
  width: 24px;
  margin-right: 4px;
}

.ag-grid-late-diagnostic {
  background-color: #fd5546;
  color: #fff;
  height: 100%;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
}

.ag-grid-row-disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  cursor: not-allowed;
}

.ag-grid-align-right {
  text-align: right;
}

.ag-grid-align-center {
  text-align: center;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 .ag-cell-value,
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 .ag-group-value {
  height: 34px;
}
ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 .ag-icon {
  color: white;
}

.ag-header-cell-sorted-asc,
.ag-header-cell-sorted-desc,
.ag-header-cell-filtered {
  color: #ea5555 !important;
}
.ag-header-cell-sorted-asc i,
.ag-header-cell-sorted-desc i,
.ag-header-cell-filtered i {
  color: #ea5555 !important;
}

.ag-header-group-text,
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
}

.ag-header-group-cell.ag-header-group-cell-with-group {
  padding: 0px 3px !important;
}

.ag-row-footer {
  font-weight: 700;
}

.ag-row-hover .display-on-hover {
  display: block;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.ag-row-hover .display-on-hover:hover {
  color: black;
}

.display-cadenas {
  cursor: auto;
}

.display-on-hover {
  display: none;
}

.ag-align-right-placeholder-20 {
  text-align: right;
}
.ag-align-right-placeholder-20::after {
  content: "";
  padding-right: 20px !important;
}

.ag-align-right-placeholder-10 {
  text-align: right;
}
.ag-align-right-placeholder-10::after {
  content: "";
  padding-right: 10px !important;
}

.ag-align-right {
  text-align: right;
}

.ag-background-color-caracs-required {
  background-color: #ffcfcf;
}

.ag-background-color {
  background-color: #f1f1f1;
}

.ag-neant {
  color: #a8adb9;
}

.ag-align-center {
  text-align: center;
}

.ag-center-header .ag-header-cell-text {
  margin: auto;
  text-align: center;
}
.ag-center-header .ag-header-group-cell-label,
.ag-center-header .ag-header-cell-label {
  margin: auto;
  text-align: center;
  flex: unset;
}

.ag-right-header .ag-header-cell-text {
  margin-left: auto;
  text-align: right;
}
.ag-right-header .ag-header-group-cell-label,
.ag-right-header .ag-header-cell-label {
  margin-left: auto;
  text-align: right;
  flex: unset;
}

.ag-header-darkgrey {
  background-color: darkgray;
}
.ag-header-darkgrey:hover {
  background-color: darkgray !important;
  color: white !important;
}
.ag-header-darkgrey .ag-header-cell-text {
  color: white;
}
.ag-header-darkgrey .ag-header-group-cell-label,
.ag-header-darkgrey .ag-header-cell-label {
  color: white;
}

.ag-header-grey {
  background-color: grey;
  color: white;
}
.ag-header-grey:hover {
  background-color: grey !important;
  color: white !important;
}

.ag-header-blue {
  background-color: #39657b;
  color: white;
}
.ag-header-blue:hover {
  background-color: #39657b !important;
  color: white !important;
}
.ag-header-blue .ag-header-group-cell-label,
.ag-header-blue .ag-header-cell-label {
  color: white;
}

.ag-cell-whitesmoke {
  background-color: whitesmoke;
}

.ag-cell-darkgrey {
  background-color: darkgrey;
  color: white;
}

.ag-cell-italic {
  font-style: italic;
  font-weight: 100;
}

.ag-cell-bold {
  font-weight: 600;
}

.ag-header-cell.ag-header-active .ag-header-icon i {
  display: unset;
}
.ag-header-cell .ag-header-icon {
  color: unset !important;
}

.ag-side-buttons,
.ag-icon.ag-icon-grip.ag-drag-handle {
  display: none;
}

.color-picker-cell {
  height: 100%;
  width: 100%;
}

.ag-cell-center {
  display: flex;
  justify-content: center;
}
.ag-cell-center .ag-selection-checkbox {
  margin: 0 !important;
}

.checkbox-select-all {
  margin-left: 6px !important;
}

.auto-input {
  color: grey;
  font-style: italic;
}

.manual-input {
  color: black;
  font-weight: 700;
}

.ag-row-group.ag-row-group-indent-0 {
  background-color: lightgray;
  font-weight: 700;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

* {
  --mdc-typography-button-letter-spacing: 1;
  --mdc-typography-caption-line-height: 10px;
  --mdc-typography-caption-font-size: 10px;
  --mdc-dialog-subhead-color: white;
  --mdc-dialog-subhead-line-height: 24px;
  --mat-tab-header-label-text-letter-spacing: 1;
  --mdc-filled-button-label-text-tracking: 1;
  --mdc-text-button-label-text-tracking: 1;
  --mdc-outlined-button-label-text-tracking: 1;
  --mdc-protected-button-label-text-tracking: 1;
}

.mat-mdc-select-placeholder {
  color: #a7adb9 !important;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #19526c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #fff9f2;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #ea5555;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #19526c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #19526c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #fff9f2;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fff9f2;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #fff9f2;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #fff9f2;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #ea5555;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ea5555;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #19526c;
  --mdc-linear-progress-track-color: rgba(25, 82, 108, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #fff9f2;
  --mdc-linear-progress-track-color: rgba(255, 249, 242, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ea5555;
  --mdc-linear-progress-track-color: rgba(234, 85, 85, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #19526c;
  --mdc-filled-text-field-focus-active-indicator-color: #19526c;
  --mdc-filled-text-field-focus-label-text-color: rgba(25, 82, 108, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #ea5555;
  --mdc-filled-text-field-error-focus-label-text-color: #ea5555;
  --mdc-filled-text-field-error-label-text-color: #ea5555;
  --mdc-filled-text-field-error-caret-color: #ea5555;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ea5555;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ea5555;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ea5555;
  --mdc-outlined-text-field-caret-color: #19526c;
  --mdc-outlined-text-field-focus-outline-color: #19526c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(25, 82, 108, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ea5555;
  --mdc-outlined-text-field-error-focus-label-text-color: #ea5555;
  --mdc-outlined-text-field-error-label-text-color: #ea5555;
  --mdc-outlined-text-field-error-hover-label-text-color: #ea5555;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ea5555;
  --mdc-outlined-text-field-error-hover-outline-color: #ea5555;
  --mdc-outlined-text-field-error-outline-color: #ea5555;
  --mat-form-field-focus-select-arrow-color: rgba(25, 82, 108, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #ea5555;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #fff9f2;
  --mdc-filled-text-field-focus-active-indicator-color: #fff9f2;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 249, 242, 0.87);
  --mdc-outlined-text-field-caret-color: #fff9f2;
  --mdc-outlined-text-field-focus-outline-color: #fff9f2;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 249, 242, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 249, 242, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #ea5555;
  --mdc-filled-text-field-focus-active-indicator-color: #ea5555;
  --mdc-filled-text-field-focus-label-text-color: rgba(234, 85, 85, 0.87);
  --mdc-outlined-text-field-caret-color: #ea5555;
  --mdc-outlined-text-field-focus-outline-color: #ea5555;
  --mdc-outlined-text-field-focus-label-text-color: rgba(234, 85, 85, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(234, 85, 85, 0.87);
}

html {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}

html {
  --mdc-filled-text-field-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(25, 82, 108, 0.87);
  --mat-select-invalid-arrow-color: rgba(234, 85, 85, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 249, 242, 0.87);
  --mat-select-invalid-arrow-color: rgba(234, 85, 85, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(234, 85, 85, 0.87);
  --mat-select-invalid-arrow-color: rgba(234, 85, 85, 0.87);
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: none;
}

html {
  --mat-select-trigger-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #19526c;
  --mdc-chip-elevated-disabled-container-color: #19526c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #fff9f2;
  --mdc-chip-elevated-disabled-container-color: #fff9f2;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #ea5555;
  --mdc-chip-elevated-disabled-container-color: #ea5555;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #164b64;
  --mdc-switch-selected-handle-color: #164b64;
  --mdc-switch-selected-hover-state-layer-color: #164b64;
  --mdc-switch-selected-pressed-state-layer-color: #164b64;
  --mdc-switch-selected-focus-handle-color: #08283d;
  --mdc-switch-selected-hover-handle-color: #08283d;
  --mdc-switch-selected-pressed-handle-color: #08283d;
  --mdc-switch-selected-focus-track-color: #5e8698;
  --mdc-switch-selected-hover-track-color: #5e8698;
  --mdc-switch-selected-pressed-track-color: #5e8698;
  --mdc-switch-selected-track-color: #5e8698;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #ffb202;
  --mdc-switch-selected-handle-color: #ffb202;
  --mdc-switch-selected-hover-state-layer-color: #ffb202;
  --mdc-switch-selected-pressed-state-layer-color: #ffb202;
  --mdc-switch-selected-focus-handle-color: #ff9301;
  --mdc-switch-selected-hover-handle-color: #ff9301;
  --mdc-switch-selected-pressed-handle-color: #ff9301;
  --mdc-switch-selected-focus-track-color: #ffce4e;
  --mdc-switch-selected-hover-track-color: #ffce4e;
  --mdc-switch-selected-pressed-track-color: #ffce4e;
  --mdc-switch-selected-track-color: #ffce4e;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e74e4e;
  --mdc-switch-selected-handle-color: #e74e4e;
  --mdc-switch-selected-hover-state-layer-color: #e74e4e;
  --mdc-switch-selected-pressed-state-layer-color: #e74e4e;
  --mdc-switch-selected-focus-handle-color: #db2a2a;
  --mdc-switch-selected-hover-handle-color: #db2a2a;
  --mdc-switch-selected-pressed-handle-color: #db2a2a;
  --mdc-switch-selected-focus-track-color: #f08888;
  --mdc-switch-selected-hover-track-color: #f08888;
  --mdc-switch-selected-pressed-track-color: #f08888;
  --mdc-switch-selected-track-color: #f08888;
}

html {
  --mdc-switch-state-layer-size: 32px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #19526c;
  --mdc-radio-selected-hover-icon-color: #19526c;
  --mdc-radio-selected-icon-color: #19526c;
  --mdc-radio-selected-pressed-icon-color: #19526c;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #19526c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff9f2;
  --mdc-radio-selected-hover-icon-color: #fff9f2;
  --mdc-radio-selected-icon-color: #fff9f2;
  --mdc-radio-selected-pressed-icon-color: #fff9f2;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #fff9f2;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ea5555;
  --mdc-radio-selected-hover-icon-color: #ea5555;
  --mdc-radio-selected-icon-color: #ea5555;
  --mdc-radio-selected-pressed-icon-color: #ea5555;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ea5555;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-radio-touch-target {
  display: none;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #19526c;
  --mdc-slider-focus-handle-color: #19526c;
  --mdc-slider-hover-handle-color: #19526c;
  --mdc-slider-active-track-color: #19526c;
  --mdc-slider-inactive-track-color: #19526c;
  --mdc-slider-with-tick-marks-inactive-container-color: #19526c;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #19526c;
  --mat-slider-hover-state-layer-color: rgba(25, 82, 108, 0.05);
  --mat-slider-focus-state-layer-color: rgba(25, 82, 108, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #fff9f2;
  --mat-slider-hover-state-layer-color: rgba(255, 249, 242, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 249, 242, 0.2);
  --mdc-slider-handle-color: #fff9f2;
  --mdc-slider-focus-handle-color: #fff9f2;
  --mdc-slider-hover-handle-color: #fff9f2;
  --mdc-slider-active-track-color: #fff9f2;
  --mdc-slider-inactive-track-color: #fff9f2;
  --mdc-slider-with-tick-marks-inactive-container-color: #fff9f2;
  --mdc-slider-with-tick-marks-active-container-color: black;
}
html .mat-warn {
  --mat-slider-ripple-color: #ea5555;
  --mat-slider-hover-state-layer-color: rgba(234, 85, 85, 0.05);
  --mat-slider-focus-state-layer-color: rgba(234, 85, 85, 0.2);
  --mdc-slider-handle-color: #ea5555;
  --mdc-slider-focus-handle-color: #ea5555;
  --mdc-slider-hover-handle-color: #ea5555;
  --mdc-slider-active-track-color: #ea5555;
  --mdc-slider-inactive-track-color: #ea5555;
  --mdc-slider-with-tick-marks-inactive-container-color: #ea5555;
  --mdc-slider-with-tick-marks-active-container-color: black;
}

html {
  --mdc-slider-label-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #19526c;
  --mdc-radio-selected-hover-icon-color: #19526c;
  --mdc-radio-selected-icon-color: #19526c;
  --mdc-radio-selected-pressed-icon-color: #19526c;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fff9f2;
  --mdc-radio-selected-hover-icon-color: #fff9f2;
  --mdc-radio-selected-icon-color: #fff9f2;
  --mdc-radio-selected-pressed-icon-color: #fff9f2;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ea5555;
  --mdc-radio-selected-hover-icon-color: #ea5555;
  --mdc-radio-selected-icon-color: #ea5555;
  --mdc-radio-selected-pressed-icon-color: #ea5555;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #19526c;
  --mdc-checkbox-selected-hover-icon-color: #19526c;
  --mdc-checkbox-selected-icon-color: #19526c;
  --mdc-checkbox-selected-pressed-icon-color: #19526c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #19526c;
  --mdc-checkbox-selected-hover-state-layer-color: #19526c;
  --mdc-checkbox-selected-pressed-state-layer-color: #19526c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff9f2;
  --mdc-checkbox-selected-hover-icon-color: #fff9f2;
  --mdc-checkbox-selected-icon-color: #fff9f2;
  --mdc-checkbox-selected-pressed-icon-color: #fff9f2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff9f2;
  --mdc-checkbox-selected-hover-state-layer-color: #fff9f2;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff9f2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ea5555;
  --mdc-checkbox-selected-hover-icon-color: #ea5555;
  --mdc-checkbox-selected-icon-color: #ea5555;
  --mdc-checkbox-selected-pressed-icon-color: #ea5555;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ea5555;
  --mdc-checkbox-selected-hover-state-layer-color: #ea5555;
  --mdc-checkbox-selected-pressed-state-layer-color: #ea5555;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #19526c;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #19526c;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}

html {
  --mdc-list-list-item-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 48px;
}

.mat-mdc-paginator {
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-filled-with-label-container-padding-top: 8px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-paginator-container-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #19526c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #19526c;
  --mat-tab-header-active-ripple-color: #19526c;
  --mat-tab-header-inactive-ripple-color: #19526c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #19526c;
  --mat-tab-header-active-hover-label-text-color: #19526c;
  --mat-tab-header-active-focus-indicator-color: #19526c;
  --mat-tab-header-active-hover-indicator-color: #19526c;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #fff9f2;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #fff9f2;
  --mat-tab-header-active-ripple-color: #fff9f2;
  --mat-tab-header-inactive-ripple-color: #fff9f2;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #fff9f2;
  --mat-tab-header-active-hover-label-text-color: #fff9f2;
  --mat-tab-header-active-focus-indicator-color: #fff9f2;
  --mat-tab-header-active-hover-indicator-color: #fff9f2;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #ea5555;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ea5555;
  --mat-tab-header-active-ripple-color: #ea5555;
  --mat-tab-header-inactive-ripple-color: #ea5555;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ea5555;
  --mat-tab-header-active-hover-label-text-color: #ea5555;
  --mat-tab-header-active-focus-indicator-color: #ea5555;
  --mat-tab-header-active-hover-indicator-color: #ea5555;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #19526c;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #fff9f2;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #ea5555;
  --mat-tab-header-with-background-foreground-color: black;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #fff9f2;
  --mdc-checkbox-selected-hover-icon-color: #fff9f2;
  --mdc-checkbox-selected-icon-color: #fff9f2;
  --mdc-checkbox-selected-pressed-icon-color: #fff9f2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #fff9f2;
  --mdc-checkbox-selected-hover-state-layer-color: #fff9f2;
  --mdc-checkbox-selected-pressed-state-layer-color: #fff9f2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #19526c;
  --mdc-checkbox-selected-hover-icon-color: #19526c;
  --mdc-checkbox-selected-icon-color: #19526c;
  --mdc-checkbox-selected-pressed-icon-color: #19526c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #19526c;
  --mdc-checkbox-selected-hover-state-layer-color: #19526c;
  --mdc-checkbox-selected-pressed-state-layer-color: #19526c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ea5555;
  --mdc-checkbox-selected-hover-icon-color: #ea5555;
  --mdc-checkbox-selected-icon-color: #ea5555;
  --mdc-checkbox-selected-pressed-icon-color: #ea5555;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ea5555;
  --mdc-checkbox-selected-hover-state-layer-color: #ea5555;
  --mdc-checkbox-selected-pressed-state-layer-color: #ea5555;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 32px;
}

.mat-mdc-checkbox-touch-target {
  display: none;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #19526c;
  --mat-text-button-state-layer-color: #19526c;
  --mat-text-button-ripple-color: rgba(25, 82, 108, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #fff9f2;
  --mat-text-button-state-layer-color: #fff9f2;
  --mat-text-button-ripple-color: rgba(255, 249, 242, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ea5555;
  --mat-text-button-state-layer-color: #ea5555;
  --mat-text-button-ripple-color: rgba(234, 85, 85, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #19526c;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #fff9f2;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ea5555;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #19526c;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #fff9f2;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ea5555;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #19526c;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #19526c;
  --mat-outlined-button-ripple-color: rgba(25, 82, 108, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #fff9f2;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #fff9f2;
  --mat-outlined-button-ripple-color: rgba(255, 249, 242, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ea5555;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ea5555;
  --mat-outlined-button-ripple-color: rgba(234, 85, 85, 0.1);
}

html {
  --mdc-text-button-container-height: 28px;
  --mdc-filled-button-container-height: 28px;
  --mdc-outlined-button-container-height: 28px;
  --mdc-protected-button-container-height: 28px;
}

.mat-mdc-button .mat-mdc-button-touch-target,
.mat-mdc-raised-button .mat-mdc-button-touch-target,
.mat-mdc-unelevated-button .mat-mdc-button-touch-target,
.mat-mdc-outlined-button .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mdc-text-button-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-state-layer-size: 48px;
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #19526c;
  --mat-icon-button-state-layer-color: #19526c;
  --mat-icon-button-ripple-color: rgba(25, 82, 108, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #fff9f2;
  --mat-icon-button-state-layer-color: #fff9f2;
  --mat-icon-button-ripple-color: rgba(255, 249, 242, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ea5555;
  --mat-icon-button-state-layer-color: #ea5555;
  --mat-icon-button-ripple-color: rgba(234, 85, 85, 0.1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary,
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #19526c;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent,
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #fff9f2;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn,
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ea5555;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mdc-extended-fab-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #fff9f2;
}

html {
  --mdc-snackbar-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}

html {
  --mat-table-header-headline-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #19526c;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #fff9f2;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #ea5555;
}

html {
  --mat-badge-background-color: #19526c;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #fff9f2;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #ea5555;
  --mat-badge-text-color: black;
}

html {
  --mat-badge-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 40px;
}

html {
  --mat-legacy-button-toggle-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-standard-button-toggle-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #19526c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(25, 82, 108, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(25, 82, 108, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(25, 82, 108, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #19526c;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(25, 82, 108, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #fff9f2;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 249, 242, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 249, 242, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 249, 242, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 249, 242, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #ea5555;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(234, 85, 85, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(234, 85, 85, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(234, 85, 85, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(234, 85, 85, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #fff9f2;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #ea5555;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

html {
  --mat-expansion-header-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #19526c;
}
.mat-icon.mat-accent {
  --mat-icon-color: #fff9f2;
}
.mat-icon.mat-warn {
  --mat-icon-color: #ea5555;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #19526c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #19526c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #19526c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #ea5555;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #ea5555;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #fff9f2;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #fff9f2;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #fff9f2;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #ea5555;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #ea5555;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #ea5555;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}

html {
  --mat-stepper-header-height: 64px;
}

html {
  --mat-stepper-container-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-stepper-header-label-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #19526c;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #fff9f2;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #ea5555;
  --mat-toolbar-container-text-color: black;
}

html {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

html {
  --mat-toolbar-title-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 40px;
}

html {
  --mat-tree-node-text-font: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 0;
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mdc-text-field {
  padding: 0 4px !important;
}

.mdc-text-field .mdc-floating-label {
  transform: translateY(-30%);
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 4px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-125%) scale(0.75) !important;
}

.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-form-field-icon-suffix,
[dir=rtl] .mat-mdc-form-field-icon-prefix {
  align-self: baseline !important;
}

.mat-mdc-form-field-icon-prefix {
  align-self: unset !important;
}

.mat-mdc-form-field-bottom-align::before {
  height: 10px !important;
}

/*
 *
 * Forms
 *
 */
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.mat-mdc-form-field-infix {
  padding-top: 20px;
  padding-bottom: 4px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: initial;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: initial !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-tab {
  padding: 0 16px 8px 16px !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: initial !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 27px !important;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-step-header {
  height: 40px !important;
}

.mdc-list {
  padding: 0 !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}

.mat-step-header {
  background-color: #e5e5ef !important;
}

::ng-deep .mat-stepper-header {
  pointer-events: none !important;
}

.cdk-virtual-scroll-spacer {
  height: 0 !important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  width: unset !important;
}

mat-form-field.w50 .mat-mdc-text-field-wrapper {
  width: 50px;
}

mat-form-field.w60 .mat-mdc-text-field-wrapper {
  width: 60px;
}

mat-form-field.w70 .mat-mdc-text-field-wrapper {
  width: 70px;
}

mat-form-field.w75 .mat-mdc-text-field-wrapper {
  width: 75px;
}

mat-form-field.w80 .mat-mdc-text-field-wrapper {
  width: 80px;
}

mat-form-field.w90 .mat-mdc-text-field-wrapper {
  width: 90px;
}

mat-form-field.w100 .mat-mdc-text-field-wrapper {
  width: 100px;
}

mat-form-field.w110 .mat-mdc-text-field-wrapper {
  width: 110px;
}

mat-form-field.w120 .mat-mdc-text-field-wrapper {
  width: 120px;
}

mat-form-field.w130 .mat-mdc-text-field-wrapper {
  width: 130px;
}

mat-form-field.w150 .mat-mdc-text-field-wrapper {
  width: 150px;
}

mat-form-field.w180 .mat-mdc-text-field-wrapper {
  width: 180px;
}

mat-form-field.w200 .mat-mdc-text-field-wrapper {
  width: 200px;
}

mat-form-field.w220 .mat-mdc-text-field-wrapper {
  width: 220px;
}

mat-form-field.w250 .mat-mdc-text-field-wrapper {
  width: 250px;
}

mat-form-field.w300 .mat-mdc-text-field-wrapper {
  width: 300px;
}

mat-form-field.w350 .mat-mdc-text-field-wrapper {
  width: 350px;
}

mat-form-field.w400 .mat-mdc-text-field-wrapper {
  width: 400px;
}

mat-form-field.w450 .mat-mdc-text-field-wrapper {
  width: 450px;
}

mat-form-field.w500 .mat-mdc-text-field-wrapper {
  width: 500px;
}

mat-form-field.pl20 {
  padding-left: 20px;
}

mat-form-field.badgeC .mat-form-field-infix {
  border-top-style: none !important;
}

.mdc-button {
  white-space: nowrap !important;
}

.mat-mdc-button-base {
  line-height: 20px !important;
  padding: 16px !important;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: center;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

/*---------------------------------------------------------------------------*/
.mat-mdc-dialog-title {
  margin: 0 !important;
  padding: 20px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.center-icon-button .mdc-button-wrapper {
  display: flex;
}
.center-icon-button .mdc-button-wrapper i {
  display: flex;
  align-self: center;
  margin-right: 4px;
}

.mdc-dialog__title {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 24px !important;
}

.mat-mdc-dialog-content {
  margin: 0 !important;
  padding: 5px 16px !important;
  max-height: 75vh !important;
}

.mat-mdc-dialog-actions {
  margin: 0 !important;
  padding: 12px 24px !important;
  float: right;
  justify-content: flex-end !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

/*---------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
.mat-mdc-dialog-container {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

@media screen and (max-width: 959px) {
  .custom-dialog-fullscreen-sm {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .custom-dialog-fullscreen-sm .mat-dialog-content {
    min-height: calc(100vh - 56px) !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .custom-dialog-fullscreen-sm .mat-dialog-container {
    min-height: 100vh !important;
  }
}
.mdc-button__label {
  display: flex;
  align-items: center;
  position: static !important;
  padding: 4px 0 !important;
  gap: 4px;
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  justify-content: space-between;
}

.company-choice {
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px 8px 8px;
}
.company-choice .mat-mdc-select-trigger {
  background-color: #39657b;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 8px;
}
.company-choice .mat-mdc-select-trigger .mat-mdc-select-arrow,
.company-choice .mat-mdc-select-trigger .mat-mdc-select-value-text {
  color: #ffffff;
}

.menu-neuf .mat-mdc-select-trigger {
  background-color: #6ab5b7;
}

.menu-ancien .mat-mdc-select-trigger {
  background-color: #39657b;
}

@font-face {
  font-family: "icons";
  src: url("./icons.woff2?d9d4353fb21fd08221cc3df645d214a0") format("woff2");
}
i[class^=icon-]:before, i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_fluent_add_24_filled:before {
  content: "\f101";
}

.icon-ic_fluent_add_circle_24_regular:before {
  content: "\f102";
}

.icon-ic_fluent_arrow_down_24_filled:before {
  content: "\f103";
}

.icon-ic_fluent_arrow_download_24_filled:before {
  content: "\f104";
}

.icon-ic_fluent_arrow_expand_24_regular:before {
  content: "\f105";
}

.icon-ic_fluent_arrow_repeat_all_24_regular:before {
  content: "\f106";
}

.icon-ic_fluent_arrow_sync_24_filled:before {
  content: "\f107";
}

.icon-ic_fluent_arrow_up_24_filled:before {
  content: "\f108";
}

.icon-ic_fluent_arrow_upload_24_regular:before {
  content: "\f109";
}

.icon-ic_fluent_attach_24_filled:before {
  content: "\f10a";
}

.icon-ic_fluent_building_multiple_24_regular:before {
  content: "\f10b";
}

.icon-ic_fluent_call_24_regular:before {
  content: "\f10c";
}

.icon-ic_fluent_caret_down_24_filled:before {
  content: "\f10d";
}

.icon-ic_fluent_caret_right_24_filled:before {
  content: "\f10e";
}

.icon-ic_fluent_checkbox_checked_24_regular:before {
  content: "\f10f";
}

.icon-ic_fluent_checkbox_unchecked_24_regular:before {
  content: "\f110";
}

.icon-ic_fluent_checkmark_24_filled:before {
  content: "\f111";
}

.icon-ic_fluent_checkmark_24_regular:before {
  content: "\f112";
}

.icon-ic_fluent_checkmark_circle_24_filled:before {
  content: "\f113";
}

.icon-ic_fluent_checkmark_circle_24_regular:before {
  content: "\f114";
}

.icon-ic_fluent_chevron_down_24_filled:before {
  content: "\f115";
}

.icon-ic_fluent_chevron_left_24_filled:before {
  content: "\f116";
}

.icon-ic_fluent_chevron_right_24_filled:before {
  content: "\f117";
}

.icon-ic_fluent_chevron_right_24_regular:before {
  content: "\f118";
}

.icon-ic_fluent_circle_24_filled:before {
  content: "\f119";
}

.icon-ic_fluent_circle_24_regular:before {
  content: "\f11a";
}

.icon-ic_fluent_cloud_backup_24_regular:before {
  content: "\f11b";
}

.icon-ic_fluent_collections_add_24_regular:before {
  content: "\f11c";
}

.icon-ic_fluent_convert_range_24_regular:before {
  content: "\f11d";
}

.icon-ic_fluent_copy_24_regular:before {
  content: "\f11e";
}

.icon-ic_fluent_copy_arrow_right_24_regular:before {
  content: "\f11f";
}

.icon-ic_fluent_delete_24_regular:before {
  content: "\f120";
}

.icon-ic_fluent_dismiss_24_regular:before {
  content: "\f121";
}

.icon-ic_fluent_document_checkmark_24_regular:before {
  content: "\f122";
}

.icon-ic_fluent_eye_show_24_regular:before {
  content: "\f123";
}

.icon-ic_fluent_filter_24_regular:before {
  content: "\f124";
}

.icon-ic_fluent_fluid_24_regular:before {
  content: "\f125";
}

.icon-ic_fluent_image_24_regular:before {
  content: "\f126";
}

.icon-ic_fluent_info_24_regular:before {
  content: "\f127";
}

.icon-ic_fluent_lock_closed_24_regular:before {
  content: "\f128";
}

.icon-ic_fluent_mail_24_regular:before {
  content: "\f129";
}

.icon-ic_fluent_more_vertical_24_regular:before {
  content: "\f12a";
}

.icon-ic_fluent_my_location_24_regular:before {
  content: "\f12b";
}

.icon-ic_fluent_navigation_24_filled:before {
  content: "\f12c";
}

.icon-ic_fluent_open_24_regular:before {
  content: "\f12d";
}

.icon-ic_fluent_print_24_regular:before {
  content: "\f12e";
}

.icon-ic_fluent_question_circle_24_regular:before {
  content: "\f12f";
}

.icon-ic_fluent_radio_button_24_filled:before {
  content: "\f130";
}

.icon-ic_fluent_radio_button_24_regular:before {
  content: "\f131";
}

.icon-ic_fluent_save_copy_24_regular:before {
  content: "\f132";
}

.icon-ic_fluent_search_24_filled:before {
  content: "\f133";
}

.icon-ic_fluent_search_24_regular:before {
  content: "\f134";
}

.icon-ic_fluent_settings_24_regular:before {
  content: "\f135";
}

.icon-ic_fluent_share_android_24_filled:before {
  content: "\f136";
}

.icon-ic_fluent_slide_hide_24_regular:before {
  content: "\f137";
}

.icon-ic_fluent_star_24_filled:before {
  content: "\f138";
}

.icon-ic_fluent_star_24_regular:before {
  content: "\f139";
}

.icon-ic_fluent_star_half_24_filled:before {
  content: "\f13a";
}

.icon-ic_fluent_table_edit_24_regular:before {
  content: "\f13b";
}

.icon-ic_fluent_toggle_left_24_regular:before {
  content: "\f13c";
}

.icon-ic_fluent_toggle_right_24_filled:before {
  content: "\f13d";
}