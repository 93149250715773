.icons.open-in-new {
  font-size: 20px;
}

.icons.search-icon {
  margin-right: 5px;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
}

.icons.more-icon {
  line-height: 24px;
  font-size: 24px;
}

.icons.navigation-icon {
  font-size: 24px;
  color: #2b526c;
  margin-right: 16px;
  display: flex;
}

.icons.people-add {
  font-size: 24px;
}

.icons.redirect-icon {
  font-size: 24px;
}

.icons {
  &.green {
    color: #178e17;
  }

  &.red {
    color: #e20000;
  }

  &.blue {
    color: #2061c4;
  }

  &.grey {
    color: #535353;
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}
