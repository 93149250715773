@font-face {
    font-family: "icons";
    src: url("./icons.woff2?d9d4353fb21fd08221cc3df645d214a0") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic_fluent_add_24_filled:before {
    content: "\f101";
}
.icon-ic_fluent_add_circle_24_regular:before {
    content: "\f102";
}
.icon-ic_fluent_arrow_down_24_filled:before {
    content: "\f103";
}
.icon-ic_fluent_arrow_download_24_filled:before {
    content: "\f104";
}
.icon-ic_fluent_arrow_expand_24_regular:before {
    content: "\f105";
}
.icon-ic_fluent_arrow_repeat_all_24_regular:before {
    content: "\f106";
}
.icon-ic_fluent_arrow_sync_24_filled:before {
    content: "\f107";
}
.icon-ic_fluent_arrow_up_24_filled:before {
    content: "\f108";
}
.icon-ic_fluent_arrow_upload_24_regular:before {
    content: "\f109";
}
.icon-ic_fluent_attach_24_filled:before {
    content: "\f10a";
}
.icon-ic_fluent_building_multiple_24_regular:before {
    content: "\f10b";
}
.icon-ic_fluent_call_24_regular:before {
    content: "\f10c";
}
.icon-ic_fluent_caret_down_24_filled:before {
    content: "\f10d";
}
.icon-ic_fluent_caret_right_24_filled:before {
    content: "\f10e";
}
.icon-ic_fluent_checkbox_checked_24_regular:before {
    content: "\f10f";
}
.icon-ic_fluent_checkbox_unchecked_24_regular:before {
    content: "\f110";
}
.icon-ic_fluent_checkmark_24_filled:before {
    content: "\f111";
}
.icon-ic_fluent_checkmark_24_regular:before {
    content: "\f112";
}
.icon-ic_fluent_checkmark_circle_24_filled:before {
    content: "\f113";
}
.icon-ic_fluent_checkmark_circle_24_regular:before {
    content: "\f114";
}
.icon-ic_fluent_chevron_down_24_filled:before {
    content: "\f115";
}
.icon-ic_fluent_chevron_left_24_filled:before {
    content: "\f116";
}
.icon-ic_fluent_chevron_right_24_filled:before {
    content: "\f117";
}
.icon-ic_fluent_chevron_right_24_regular:before {
    content: "\f118";
}
.icon-ic_fluent_circle_24_filled:before {
    content: "\f119";
}
.icon-ic_fluent_circle_24_regular:before {
    content: "\f11a";
}
.icon-ic_fluent_cloud_backup_24_regular:before {
    content: "\f11b";
}
.icon-ic_fluent_collections_add_24_regular:before {
    content: "\f11c";
}
.icon-ic_fluent_convert_range_24_regular:before {
    content: "\f11d";
}
.icon-ic_fluent_copy_24_regular:before {
    content: "\f11e";
}
.icon-ic_fluent_copy_arrow_right_24_regular:before {
    content: "\f11f";
}
.icon-ic_fluent_delete_24_regular:before {
    content: "\f120";
}
.icon-ic_fluent_dismiss_24_regular:before {
    content: "\f121";
}
.icon-ic_fluent_document_checkmark_24_regular:before {
    content: "\f122";
}
.icon-ic_fluent_eye_show_24_regular:before {
    content: "\f123";
}
.icon-ic_fluent_filter_24_regular:before {
    content: "\f124";
}
.icon-ic_fluent_fluid_24_regular:before {
    content: "\f125";
}
.icon-ic_fluent_image_24_regular:before {
    content: "\f126";
}
.icon-ic_fluent_info_24_regular:before {
    content: "\f127";
}
.icon-ic_fluent_lock_closed_24_regular:before {
    content: "\f128";
}
.icon-ic_fluent_mail_24_regular:before {
    content: "\f129";
}
.icon-ic_fluent_more_vertical_24_regular:before {
    content: "\f12a";
}
.icon-ic_fluent_my_location_24_regular:before {
    content: "\f12b";
}
.icon-ic_fluent_navigation_24_filled:before {
    content: "\f12c";
}
.icon-ic_fluent_open_24_regular:before {
    content: "\f12d";
}
.icon-ic_fluent_print_24_regular:before {
    content: "\f12e";
}
.icon-ic_fluent_question_circle_24_regular:before {
    content: "\f12f";
}
.icon-ic_fluent_radio_button_24_filled:before {
    content: "\f130";
}
.icon-ic_fluent_radio_button_24_regular:before {
    content: "\f131";
}
.icon-ic_fluent_save_copy_24_regular:before {
    content: "\f132";
}
.icon-ic_fluent_search_24_filled:before {
    content: "\f133";
}
.icon-ic_fluent_search_24_regular:before {
    content: "\f134";
}
.icon-ic_fluent_settings_24_regular:before {
    content: "\f135";
}
.icon-ic_fluent_share_android_24_filled:before {
    content: "\f136";
}
.icon-ic_fluent_slide_hide_24_regular:before {
    content: "\f137";
}
.icon-ic_fluent_star_24_filled:before {
    content: "\f138";
}
.icon-ic_fluent_star_24_regular:before {
    content: "\f139";
}
.icon-ic_fluent_star_half_24_filled:before {
    content: "\f13a";
}
.icon-ic_fluent_table_edit_24_regular:before {
    content: "\f13b";
}
.icon-ic_fluent_toggle_left_24_regular:before {
    content: "\f13c";
}
.icon-ic_fluent_toggle_right_24_filled:before {
    content: "\f13d";
}
