.info {
  background-color: whitesmoke;
  border-radius: 8px;
  color: darkgray;
  padding: 8px;
  margin: 0 0 16px 0 !important;

  i {
    font-size: 24px;
    line-height: 24px;
  }
}

.info-800 {
  width: 800px;
}

.info-700 {
  width: 700px;
}

.warn {
  background-color: #fff0f0;
  border-radius: 8px;
  color: rgb(238, 117, 117);
  padding: 8px;
  margin: 0 0 16px 0 !important;
}
