@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';

[class*='ag-theme-'] {
  --ag-row-group-indent-size: 20px;
}

.ag-grid-border-16 {
  border-right: 16px solid white;
  border-left: 16px solid white;
  box-sizing: border-box;
}

.ag-theme-alpine {
  .ag-root-wrapper {
    border-right: 0px solid;
    border-left: 0px solid;
    border-top: solid 1px #bdc3c7;
  }

  .ag-row-group {
    span {
      height: 33px !important;
      line-height: 33px !important;
    }
  }

  .ag-ltr .ag-cell-focus {
    border: 1px solid rgba(22, 145, 133, 1) !important;
  }

  .ag-row:not(.ag-full-width-row, .ag-row-group) {
    border-bottom-style: unset;

    // Pour Exclure le lignage des rowgroup
    .ag-cell:not(.ag-cell-focus) {
      border-top: solid 1px transparent !important;
      border-bottom: solid 1px #e8ecee !important;
      border-right: solid 1px #e8ecee !important;
      border-left: solid 1px transparent !important;
    }
  }

  .ag-header-cell::after,
  .ag-header-group-cell::after {
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #bdc3c7;
  }

  .ag-row-drag {
    // equivalent to gulp-34-handler
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0%29 --%3E%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 34 34' style='enable-background:new 0 0 34 34;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M23,3H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,3,23,3z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,13H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,13,23,13z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,23H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,23,23,23z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,33H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,33,23,33z'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    margin: 2px;
    border: 2px solid map-get($md-primary, 500);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: map-get($md-primary, 500) transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ag-cell {
    padding: 0px 3px !important;
    text-overflow: clip;
    line-height: 31px;
  }

  .ag-header {
    background-color: #f1f1f1 !important;
    border-bottom-color: #e8ecee;
    border-bottom: 1px solid #bdc3c7;

    .ag-header-row {
      color: #6f7782;
      font-weight: 400 !important;

      .ag-header-cell {
        padding: 0px 3px !important;
      }

      &:first-child {
        .ag-header-cell {
          border-top: 0px !important;
        }
      }

      .ag-header-cell:hover {
        background-color: #f6f8f9;
        color: #151b26;
        fill: #151b26;

        .ag-header-icon {
          background-color: #f6f8f9;
        }
      }

      .ag-cell-label-container {
        position: relative;
      }

      .ag-header-cell-menu-button {
        position: absolute;
        bottom: 0;
        height: 12px;
      }
    }
  }

  .ag-popup-editor {
    z-index: 1000;

    input {
      height: 30px;
    }

    textarea {
      min-height: 30px;
    }
  }

  // @for $i from 0 through 15 {
  //   $base: 8;
  //   $padding: $base * $i;
  //   .ag-row-group-indent-#{$i} {
  //     padding-left: $padding + 16px !important;
  //   }
  // }

  .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 24px !important;
  }

  .ag-cell-inline-editing {
    height: 35px;
  }
}

@for $i from 0 through 15 {
  ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-#{$i} {
    color: #151b26 !important;
    font-size: 14px;
    font-weight: 700;
    border-bottom: solid 2px #e8ecee !important;

    div[col-id*='ag-Grid-AutoColumn'] {
      overflow: initial;
      .ag-group-value {
        overflow: initial;
      }
    }

    .ag-icon {
      color: #151b26 !important;
    }

    &:hover {
      background-color: white !important;
    }
  }
}

.ag-root {
  border: none !important;
}

.multiple-lines-header {
  .ag-header-cell-label .ag-header-cell-text,
  .ag-header-group-text {
    white-space: normal !important;
    line-height: 16px;
    max-height: 35px;
  }
}

.ag-row-odd,
.ag-row-even {
  background-color: #ffffff !important;
}

.ag-row-hover {
  background-color: #f6f8f9 !important;
}

.ag-row-selected {
  background-color: map-get($md-primary, 100) !important;
}

.align-right {
  text-align: end;
}

.greyed-row {
  background-color: map-get($md-paletteGrey, 50) !important;
}

.greyed-text {
  color: map-get($md-paletteGrey, 200) !important;
}

.bold-row {
  font-weight: 700;
}

.icon-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 17px;
    padding-right: 5px;
  }
}

.icon-cell-disabled {
  cursor: auto !important;
}

.icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 21px;
    padding-right: 5px;
    padding-top: 2px;
  }
}

.choix-cell {
  display: flex;

  i {
    display: flex;
    align-items: center;
  }

  div {
    display: flex;
    align-self: center;
  }
}

.svg-icon-cell {
  height: 100%;
  img {
    width: 28px;
  }
}

.svg-icon-cell-analyze-synthetic {
  height: 100%;
  img {
    width: 24px;
    margin-right: 4px;
  }
}

.ag-grid-late-diagnostic {
  background-color: #fd5546;
  color: #fff;
  height: 100%;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
}

.ag-grid-row-disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  cursor: not-allowed;
}

.ag-grid-align-right {
  text-align: right;
}

.ag-grid-align-center {
  text-align: center;
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 {
  // line-height: 35px;

  .ag-cell-value,
  .ag-group-value {
    height: 34px;
  }

  .ag-icon {
    color: white;
  }
}

.ag-header-cell-sorted-asc,
.ag-header-cell-sorted-desc,
.ag-header-cell-filtered {
  color: map-get($md-warn, 500) !important;

  i {
    color: map-get($md-warn, 500) !important;
  }
}

.ag-header-group-text,
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
}

.ag-header-group-cell.ag-header-group-cell-with-group {
  padding: 0px 3px !important;
}

.ag-row-footer {
  font-weight: 700;
}

.ag-row-hover {
  .display-on-hover {
    display: block;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    &:hover {
      color: black;
    }
  }
}

.display-cadenas {
  cursor: auto;
}

.display-on-hover {
  display: none;
}

.ag-align-right-placeholder-20 {
  text-align: right;

  &::after {
    content: '';
    padding-right: 20px !important;
  }
}

.ag-align-right-placeholder-10 {
  text-align: right;

  &::after {
    content: '';
    padding-right: 10px !important;
  }
}

.ag-align-right {
  text-align: right;
}

.ag-background-color-caracs-required {
  background-color: #ffcfcf;
}

.ag-background-color {
  background-color: #f1f1f1;
}

.ag-neant {
  color: #a8adb9;
}

.ag-align-center {
  text-align: center;
}

.ag-center-header {
  .ag-header-cell-text {
    margin: auto;
    text-align: center;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    margin: auto;
    text-align: center;
    flex: unset;
  }
}

.ag-right-header {
  .ag-header-cell-text {
    margin-left: auto;
    text-align: right;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    margin-left: auto;
    text-align: right;
    flex: unset;
  }
}

.ag-header-darkgrey {
  &:hover {
    background-color: darkgray !important;
    color: white !important;
  }
  background-color: darkgray;
  .ag-header-cell-text {
    color: white;
  }
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: white;
  }
}

.ag-header-grey {
  &:hover {
    background-color: grey !important;
    color: white !important;
  }
  background-color: grey;
  color: white;
}

.ag-header-blue {
  &:hover {
    background-color: #39657b !important;
    color: white !important;
  }
  background-color: #39657b;
  color: white;
  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: white;
  }
}

.ag-cell-whitesmoke {
  background-color: whitesmoke;
}

.ag-cell-darkgrey {
  background-color: darkgrey;
  color: white;
}

.ag-cell-italic {
  font-style: italic;
  font-weight: 100;
}

.ag-cell-bold {
  font-weight: 600;
}

.ag-header-cell {
  &.ag-header-active {
    .ag-header-icon {
      i {
        display: unset;
      }
    }
  }

  .ag-header-icon {
    color: unset !important;
  }
}

.ag-side-buttons,
.ag-icon.ag-icon-grip.ag-drag-handle {
  display: none;
}

.color-picker-cell {
  height: 100%;
  width: 100%;
}

.ag-cell-center {
  .ag-selection-checkbox {
    margin: 0 !important;
  }
  display: flex;
  justify-content: center;
}

.checkbox-select-all {
  margin-left: 6px !important;
}


.auto-input {
  color: grey;
  font-style: italic;
}

.manual-input {
  color: black;
  font-weight: 700;
}


.ag-row-group.ag-row-group-indent-0 {
  background-color: lightgray;
  font-weight: 700;
}
