// @import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '@angular/material/theming';

@use '@angular/material' as mat;
@import '../abstracts/variables';

@include mat.core();

//$custom-typography: mat.typography-config(
//  $subheading-2: mat.define-typography-level(14px, 28px, 400),
//);
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif !default;

* {
  --mdc-typography-button-letter-spacing: 1;
  --mdc-typography-caption-line-height: 10px;
  --mdc-typography-caption-font-size: 10px;
  // --mdc-filled-button-label-text-color: white !important;
  --mdc-dialog-subhead-color: white;
  --mdc-dialog-subhead-line-height: 24px;
  --mat-tab-header-label-text-letter-spacing: 1;
  --mdc-filled-button-label-text-tracking: 1;
  --mdc-text-button-label-text-tracking: 1;
  --mdc-outlined-button-label-text-tracking: 1;
  --mdc-protected-button-label-text-tracking: 1;
}

.mat-mdc-select-placeholder {
  color: #a7adb9 !important;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
}

//@include angular-material-typography($custom-typography);

$up-app-primary: mat.define-palette($md-primary);
$up-app-accent: mat.define-palette($md-accent, A200, A100, A400);
$up-app-warn: mat.define-palette($md-warn);

$up-body-1: mat.define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: 14px,
  $line-height: 24px,
  $letter-spacing: normal
);

$up-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $up-app-primary,
      accent: $up-app-accent,
      warn: $up-app-warn
    ),
    typography:
      mat.define-typography-config(
        $font-family: $font-family,
        $body-1: $up-body-1
      ),
    density: -2
  )
);

@include mat.all-component-themes($up-app-theme);

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 0;
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mdc-text-field {
  padding: 0 4px !important;
}

.mdc-text-field .mdc-floating-label {
  transform: translateY(-30%);
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 4px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-125%) scale(0.75) !important;
}

.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-form-field-icon-suffix,
[dir='rtl'] .mat-mdc-form-field-icon-prefix {
  align-self: baseline !important;
}
.mat-mdc-form-field-icon-prefix {
  align-self: unset !important;
}

.mat-mdc-form-field-bottom-align::before {
  height: 10px !important;
}

/*
 *
 * Forms
 *
 */

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.mat-mdc-form-field-infix {
  padding-top: 20px;
  padding-bottom: 4px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: initial;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: initial !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-tab {
  padding: 0 16px 8px 16px !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: initial !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 27px !important;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-step-header {
  height: 40px !important;
}

.mdc-list {
  padding: 0 !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}

.mat-step-header {
  background-color: #e5e5ef !important;
}

::ng-deep .mat-stepper-header {
  pointer-events: none !important;
}

.cdk-virtual-scroll-spacer {
  height: 0 !important;
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    width: unset !important;
  }
}

//
//.mat-form-field-label,
//.mat-form-field-label-wrapper {
//  overflow: visible !important;
//}
//
//mat-form-field.remove-infix {
//  .mat-form-field-flex {
//    padding-top: 0 !important;
//  }
//
//  .mat-mdc-text-field-wrapper {
//    padding-bottom: 10px;
//  }
//
//  .mat-form-field-infix {
//    padding: 5px 0;
//  }
//
//  .mat-form-field-underline {
//    top: 1.5em !important;
//  }
//
//  display: inline-block;
//  margin-bottom: -1.5em !important;
//
//  > div {
//    background-color: transparent !important;
//
//    > div {
//      background-color: white;
//      border-radius: 4px;
//
//      > div {
//        border-top: 0;
//      }
//    }
//  }
//}
//
//mat-form-field.remove-infix.background-grey {
//  > div {
//    > div {
//      > div {
//        margin: 0 5px 0 10px;
//
//        > span.gulp {
//          font-size: 18px;
//          line-height: 12px;
//          vertical-align: middle;
//        }
//      }
//    }
//  }
//}
//
//mat-form-field.remove-infix.background-grey:not(:focus-within) {
//  > div {
//    > div {
//      background-color: whitesmoke;
//    }
//  }
//}
//
//mat-form-field.remove-infix-max-char {
//  margin-bottom: -1.25em !important;
//
//  > div {
//    background-color: transparent !important;
//
//    > div:nth-of-type(-n + 2) {
//      background-color: white;
//
//      > div {
//        border-top: 0;
//      }
//    }
//  }
//}

mat-form-field.w50 {
  .mat-mdc-text-field-wrapper {
    width: 50px;
  }
}

mat-form-field.w60 {
  .mat-mdc-text-field-wrapper {
    width: 60px;
  }
}

mat-form-field.w70 {
  .mat-mdc-text-field-wrapper {
    width: 70px;
  }
}

mat-form-field.w75 {
  .mat-mdc-text-field-wrapper {
    width: 75px;
  }
}

mat-form-field.w80 {
  .mat-mdc-text-field-wrapper {
    width: 80px;
  }
}

mat-form-field.w90 {
  .mat-mdc-text-field-wrapper {
    width: 90px;
  }
}

mat-form-field.w100 {
  .mat-mdc-text-field-wrapper {
    width: 100px;
  }
}

mat-form-field.w110 {
  .mat-mdc-text-field-wrapper {
    width: 110px;
  }
}

mat-form-field.w120 {
  .mat-mdc-text-field-wrapper {
    width: 120px;
  }
}

mat-form-field.w130 {
  .mat-mdc-text-field-wrapper {
    width: 130px;
  }
}

mat-form-field.w150 {
  .mat-mdc-text-field-wrapper {
    width: 150px;
  }
}

mat-form-field.w180 {
  .mat-mdc-text-field-wrapper {
    width: 180px;
  }
}

mat-form-field.w200 {
  .mat-mdc-text-field-wrapper {
    width: 200px;
  }
}

mat-form-field.w220 {
  .mat-mdc-text-field-wrapper {
    width: 220px;
  }
}

mat-form-field.w250 {
  .mat-mdc-text-field-wrapper {
    width: 250px;
  }
}

mat-form-field.w300 {
  .mat-mdc-text-field-wrapper {
    width: 300px;
  }
}

mat-form-field.w350 {
  .mat-mdc-text-field-wrapper {
    width: 350px;
  }
}

mat-form-field.w400 {
  .mat-mdc-text-field-wrapper {
    width: 400px;
  }
}

mat-form-field.w450 {
  .mat-mdc-text-field-wrapper {
    width: 450px;
  }
}

mat-form-field.w500 {
  .mat-mdc-text-field-wrapper {
    width: 500px;
  }
}

mat-form-field.pl20 {
  padding-left: 20px;
}

mat-form-field.badgeC {
  .mat-form-field-infix {
    border-top-style: none !important;
  }
}

.mdc-button {
  white-space: nowrap !important;
}

.mat-mdc-button-base {
  line-height: 20px !important;
  padding: 16px !important;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: center;
}

.cdk-overlay-pane {
  // max-width: 100% !important;
  // width: unset !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

//
//mat-form-field.autocomp {
//  cursor: pointer !important;
//
//  input {
//    cursor: pointer !important;
//  }
//}
//
//.mat-input-element:disabled {
//  color: rgba(black, 0.8) !important;
//}
//
//.mat-select-disabled .mat-select-value {
//  color: rgba(black, 0.8) !important;
//}
//
//.mdc-button-toggle-appearance-standard .mdc-button-toggle-label-content {
//  line-height: 32px !important;
//  padding: 0 8px !important;
//}
//
//.mat-drawer-content {
//  overflow: hidden !important;
//}
//
///*
// *
// * Chips
// *
// */
//.mat-chip.mat-standard-chip {
//  min-height: 24px !important;
//  font-weight: normal !important;
//}
//
///*
// *
// * Icons
// *
// */
//
//.material-icons-outlined.option {
//  font-size: 16px;
//  height: 16px;
//  width: 16px;
//  line-height: 16px;
//}
//
//.material-icons-outlined {
//  &.green {
//    color: #178e17;
//  }
//
//  &.red {
//    color: #e20000;
//  }
//
//  &.blue {
//    color: #2061c4;
//  }
//
//  &.grey {
//    color: #535353;
//  }
//}
//
///*
// *
// * Modals
// *
// */
//
//.mat-dialog-container {
//  padding: 0 !important;
//}
//
//// Backdrop padding for navbar feature
//
//.cdk-overlay-backdrop {
//  padding-bottom: 48px;
//}
//
//.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//  &.dark-backdrop {
//    background: rgba(0, 0, 0, 0.288);
//  }
//}

/*---------------------------------------------------------------------------*/
// Default Material overrides

.mat-mdc-dialog-title {
  margin: 0 !important;
  padding: 20px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.center-icon-button {
  .mdc-button-wrapper {
    display: flex;

    i {
      display: flex;
      align-self: center;
      margin-right: 4px;
    }
  }
}

.mdc-dialog__title {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 24px !important;
}

.mat-mdc-dialog-content {
  margin: 0 !important;
  padding: 5px 16px !important;
  max-height: 75vh !important;
}

.mat-mdc-dialog-actions {
  margin: 0 !important;
  padding: 12px 24px !important;
  float: right;
  justify-content: flex-end !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

/*---------------------------------------------------------------------------*/
// Customs Material overrides

//// fit height
//.fit-dialog,
//.fit-dialog .mat-tab-body-wrapper,
//.fit-dialog .mat-tab-body-wrapper,
//.fit-dialog .mat-tab-body-wrapper .mat-tab-body,
//.fit-dialog .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
//  height: 100%;
//  overflow: hidden !important;
//}

/*---------------------------------------------------------------------------*/
// Sizes Material overrides

.mat-mdc-dialog-container {
  // To give full control to dialog-content div
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

//.dialog-content-no-paddings {
//  .mat-dialog-content {
//    padding: 0 !important;
//  }
//}
//
//// passage en pleine hauteur
//.custom-dialog-fullheight {
//  height: 98% !important;
//  max-height: 100% !important;
//
//  .mat-dialog-content {
//    min-height: calc(98vh - 56px) !important; // 56px from header
//
//    // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
//    box-sizing: border-box;
//    display: flex;
//    flex-direction: column;
//  }
//
//  .mat-dialog-container {
//    min-height: 98vh !important;
//  }
//}

//@media screen and (max-width: 600px) {
//  // Equivalent to 'smarthphone'
//  .custom-dialog-fullscreen-xs {
//    height: 100% !important;
//    max-height: 100% !important;
//    width: 100% !important;
//    max-width: 100% !important;
//
//    .mat-dialog-content {
//      min-height: calc(100vh - 56px) !important; // 56px from header
//
//      // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
//      box-sizing: border-box;
//      display: flex;
//      flex-direction: column;
//      justify-content: center;
//    }
//
//    .mat-dialog-container {
//      min-height: 100vh !important;
//    }
//  }
//
//  .fit-dialog,
//  .fit-dialog .mat-tab-body-wrapper,
//  .fit-dialog .mat-tab-body-wrapper,
//  .fit-dialog .mat-tab-body-wrapper .mat-tab-body,
//  .fit-dialog .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
//    max-height: calc(100vh - 56px) !important;
//  }
//}

@media screen and (max-width: 959px) {
  // Equivalent to 'tablet'
  .custom-dialog-fullscreen-sm {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    .mat-dialog-content {
      min-height: calc(100vh - 56px) !important; // 56px from header

      // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .mat-dialog-container {
      min-height: 100vh !important;
    }
  }
}

.mdc-button__label {
  display: flex;
  align-items: center;
  position: static !important;
  padding: 4px 0 !important;
  gap: 4px;
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  justify-content: space-between;
}

//
//.mat-tab-header {
//  border-bottom: none;
//}
//
//.mat-flat-button.mat-primary {
//  color: white;
//}
//
//.mat-tab-link {
//  min-width: 60px !important;
//  padding: 0 !important;
//  height: 40px !important;
//  margin-right: 24px;
//}
//
//.rate-profile-select {
//  .mat-select-trigger {
//    .mat-select-value {
//      text-align: right;
//    }
//
//    padding-left: 0 !important;
//    padding-right: 0 !important;
//  }
//}
//
.company-choice {
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px 8px 8px;

  .mat-mdc-select-trigger {
    background-color: #39657b;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 8px;

    .mat-mdc-select-arrow,
    .mat-mdc-select-value-text {
      color: #ffffff;
    }
  }
}

.menu-neuf {
  .mat-mdc-select-trigger {
    background-color: #6ab5b7;
  }
}

.menu-ancien {
  .mat-mdc-select-trigger {
    background-color: #39657b;
  }
}

//
//.mat-option {
//  .mat-pseudo-checkbox-checked {
//    background-color: map-get($md-primary, 500);
//  }
//}
//
//.mat-stepper-horizontal.no-margin {
//  .mat-horizontal-content-container {
//    margin: 0;
//    padding: 0;
//  }
//}
//
//.mat-horizontal-stepper-header {
//  height: 32px !important;
//}
//
//.mat-step-header .mat-step-icon-selected {
//  color: white;
//}
//
//app-prospect-stepper {
//  .mat-horizontal-stepper-header-container {
//    width: 500px;
//    margin: auto;
//  }
//}
//
//app-add-offer {
//  .mat-dialog-actions {
//    padding: 0 !important;
//  }
//}
